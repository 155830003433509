import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ProductView from "../components/product-item-view"

import Head from "../components/head"
import Layout from "../components/layout"
import { normalizedProducts, Product } from "../models/product"
import { FlexibleRowContainer } from "../styles/components/shared-styles"
import * as bundleProductsStyles from "../styles/components/bundle-products.module.scss"
import styled from "../../node_modules/@emotion/styled"

const BlogPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allProductsJson {
          nodes {
            slug
            name
            featuredImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            screenshotImages {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        allStripePrice(filter: { active: { eq: true } }) {
          nodes {
            id
            unit_amount
            currency
            product {
              metadata {
                slug
              }
              name
            }
          }
        }
      }
    `
  )

  const allLocalProductsData = data.allProductsJson.nodes
  const allStripeProductsData = data.allStripePrice.nodes

  const products = normalizedProducts(
    allLocalProductsData,
    allStripeProductsData
  )

  return (
    <div>
      <Layout>
        <Head title="iOS app templates" />
        <Heading>All iOS App Templates</Heading>
        <FlexibleRowContainer
          className={bundleProductsStyles.productsContainer}
        >
          {products.map((product: Product) => {
            return <ProductView product={product} />
          })}
        </FlexibleRowContainer>
      </Layout>
    </div>
  )
}

const Heading = styled.h1`
  margin-bottom: 2rem;
`

export default BlogPage
